<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="monthCard-edit">
            <el-form :model="form" ref="form" :rules="rules" label-width="100px" label-position="left" >
                <div class="a-fs-16 a-fw-700 a-flex-rfsc" style="margin-bottom: 16px">
                    <span>{{ form.type == 1 ? '白名单' : '黑名单' }}{{ id ? '修改' : '添加' }}</span>
                </div>
                <el-form-item label="用户手机号" prop="mobile">
                    <el-input class="inputBox" maxlength="12" :disabled='id?true:false' clearable v-model="form.mobile" placeholder="请输入用户手机号"></el-input>
                </el-form-item>
                <el-form-item label="时间范围" prop="startTime">
                    <div class="a-flex-rfsc" style="height:32px">
                        <el-radio-group v-model="form.timeType">
                            <el-radio 
                                v-for="(item,index) in activityPeriodDic"
                                :key="index"
                                :label="item.value" >{{ item.text }}</el-radio>
                        </el-radio-group>
                        <jd-datetime-range 
                            type='daterange'
                            v-if="form.timeType == 0"
                            style="margin:0;padding: 0;margin-left: 20px;" 
                            label="" 
                            ref="daterange"
                            valueFormat="yyyy-MM-dd" 
                            :minDate.sync="form.startTime" 
                            :maxDate.sync="form.endTime" />
                    </div>
                </el-form-item>
               
                <el-form-item label="影响范围" prop="scopeType">
                    <div class="a-flex-rfsc">
                        <!-- <el-select style="width: 225px;" v-model="form.scopeType" @change='scopeTypeChange' multiple placeholder="请选择指定范围（可多选）">
                            <el-option label="全部" :value="4"></el-option>
                            <el-option label="商户" :value="1"></el-option>
                            <el-option label="项目" :value="2"></el-option>
                            <el-option label="站点" :value="3"></el-option>
                        </el-select> -->
                        <div 
                            v-for="(item, index) in scopeTypeDic" 
                            :key="index"
                            class="selectedBtn"
                            :class="form.scopeType.indexOf(item.value) != -1 ? 'selectedBtnAc' : ''"
                            @click="handleChooseScopeType(item.value)">
                            <span>{{ item.label }}</span>
                        </div>
                    </div>
                    
                    <le-company-under-select 
                        v-if="form.scopeType.indexOf(1) != -1 && form.scopeType.indexOf(4) == -1"
                        label="商户"
                        placeholder="请选择商户（可多选）"
                        style="width:425px"
                        reserveKeyword 
                        :collapse='false'
                        multiple 
                        large
                        isObj
                        v-model="companyDatas">
                    </le-company-under-select>
                    <le-select-remote-search 
                        v-if="form.scopeType.indexOf(2) != -1 && form.scopeType.indexOf(4) == -1"
                        label="项目"
                        style="width:425px" 
                        reserveKeyword 
                        multiple 
                        large
                        isObj
                        :collapse='false'
                        v-model="incomeDatas" 
                        :defaultOptions='incomeDefaultDatas'
                        :options="optionsincomeDistri" 
                        placeholder="请选择项目（可多选）" />
                    <le-select-remote-search 
                        v-if="form.scopeType.indexOf(3) != -1 && form.scopeType.indexOf(4) == -1"
                        label="站点"
                        style="width:425px"
                        reserveKeyword 
                        multiple 
                        large
                        isObj
                        :collapse='false'
                        v-model="stationDatas" 
                        :defaultOptions='stationDefaultDatas'
                        :options="optionsStation" placeholder="请选择站点（可多选）" />
                </el-form-item>
                <el-form-item label="备注" prop="mobile">
                    <el-input class="inputBox" type='textarea' autosize maxlength="100" clearable v-model="form.remark" placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>
            <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div>
   
        </el-card>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import util from '../../../src/utils/util'
import { mapState } from 'vuex';
export default {
    data() {
        var checkStartTime = (rule, value, callback) => {
            if(this.form.timeType==0 && !this.form.startTime){
                callback(new Error('请选择时间范围'))
            }else{
                callback()
            }
        };
        var checkJoinScope = (rule, value, callback) => {
            if(!this.form.scopeType.length){
                callback(new Error('请选择影响范围'))
            }else{
                if(this.form.scopeType.indexOf(1) != -1 && !this.companyDatas.length) {
                    callback(new Error('请选择影响商户'))
                }else if(this.form.scopeType.indexOf(2) != -1 && !this.incomeDatas.length) {
                    callback(new Error('请选择影响项目'))
                }else if(this.form.scopeType.indexOf(3) != -1 && !this.stationDatas.length) {
                    callback(new Error('请选择影响站点'))
                }else {
                    callback()
                }
            }
        };
        
        return {
            id: '',
            util: util,
            form:{
                endTime: '',
                mobile: "",
                scopeValidList: [],
                startTime: "",
                timeType: "0",
                type: '',
                scopeType: [4],
                remark: ''
            },
            companyDatas: [],
            incomeDatas: [],
            stationDatas: [],
            incomeDefaultDatas: [],
            stationDefaultDatas: [],
            rules:{
                mobile: [{required: true, message:'请输入用户手机号', trigger: 'blur'}],
                startTime: [{ validator: checkStartTime, trigger: 'change' }],
                scopeType: [{ validator: checkJoinScope, trigger: 'change' }],
            },
            optionsincomeDistri: {
                url: this.$Config.apiUrl.incomeDistriList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
                pageSize: 200,
            },//项目数据
            optionsStation: {
                url: this.$Config.apiUrl.getStationInfoList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "searchKey",
                pageSize: 200,
            },//站点数据
            activityPeriodDic: [],//失效时间类型字典
            scopeTypeDic: [
                {
                    value: 4,
                    label: '全部',
                },{
                    value: 1,
                    label: '商户',
                },{
                    value: 2,
                    label: '项目',
                },{
                    value: 3,
                    label: '站点',
                }
            ]
        };
    },
    computed: {
        ...mapState({
            company: state => state.company.company,
        })
    },
    created () {
        this.$getDic('activityPeriod').then(res=>{ this.activityPeriodDic = res; })
        if(this.company.isSystem != 1) { //只有系统商户才有“全部”选项
            this.scopeTypeDic = this.scopeTypeDic.filter(res=>{
                return res.value != 4
            })
        }
    },
    mounted () {
        this.id = this.$route.query.id?this.$route.query.id:''
        this.form.type = this.$route.query.type?this.$route.query.type:''
        if(this.id) {
            this.getDetails()
        }
    },
    methods: {
        getDetails () {
            this.$Axios._post({
                url: this.$Config.apiUrl.userWhiteOrBlackDetails,
                method: "post",
                params: {
                    id: this.id
                }
            }).then(({ data }) => {
                Object.assign(this.form, data, {
                    timeType: JSON.stringify(data.timeType)
                });
                if(data.timeType == 0) {
                    this.form.startTime = data.startTime
                    this.form.endTime = data.endTime
                }else {
                    this.form.startTime = ''
                    this.form.endTime = ''
                }
                // 初始化影响范围
                this.companyDatas = data.scopeValidList.filter(res=>{
                    return res.scopeType == 1
                }).map(res=>{
                    return {
                        label: res.name,
                        value: res.scopeId
                    }
                })
                
                this.incomeDatas = data.scopeValidList.filter(res=>{
                    return res.scopeType == 2
                }).map(res=>{
                    return {
                        label: res.name,
                        value: res.scopeId
                    }
                })

                this.incomeDefaultDatas = this.incomeDatas.map(item=>{
                    return {
                        name: item.label,
                        id: item.value
                    }
                })
                
                this.stationDatas = data.scopeValidList.filter(res=>{
                    return res.scopeType == 3
                }).map(res=>{
                    return {
                        label: res.name,
                        value: res.scopeId
                    }
                })

                this.stationDefaultDatas = this.stationDatas.map(item=>{
                    return {
                        name: item.label,
                        id: item.value
                    }
                })
                
                // 影响范围是否选择全部
                let isAll = data.scopeValidList.find(res=>{
                    return res.scopeType == 4
                })
    
                if(isAll) {
                    this.form.scopeType = [4]
                }else {
                    this.form.scopeType = []
                    if(this.companyDatas.length) {
                        this.form.scopeType.push(1)
                    }
                    if(this.incomeDatas.length) {
                        this.form.scopeType.push(2)
                    }
                    if(this.stationDatas.length) {
                        this.form.scopeType.push(3)
                    }
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        submit () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$confirm('是否确认提交？','温馨提示')
                    .then(_=>{
                        let scopeValidList = []
                        if(this.form.scopeType.indexOf(1) != -1) {
                            let datas = this.companyDatas.map(item=>{
                                return {
                                    name: item.label,
                                    scopeId: item.value,
                                    scopeType: 1
                                }
                            })
                            scopeValidList = scopeValidList.concat(datas)
                        }
                        if(this.form.scopeType.indexOf(2) != -1) {
                            let datas = this.incomeDatas.map(item=>{
                                return {
                                    name: item.label,
                                    scopeId: item.value,
                                    scopeType: 2
                                }
                            })
                            scopeValidList = scopeValidList.concat(datas)
                        }
                        if(this.form.scopeType.indexOf(3) != -1) {
                            let datas = this.stationDatas.map(item=>{
                                return {
                                    name: item.label,
                                    scopeId: item.value,
                                    scopeType: 3
                                }
                            })
                            scopeValidList = scopeValidList.concat(datas)
                        }
                        if(this.form.scopeType.indexOf(4) != -1) { //如果影响范围选择了全部，则固定影响范围值为4--全部
                            scopeValidList = [{
                                name: '全部',
                                scopeId: '',
                                scopeType: 4
                            }]
                        }
                        let startTime = this.form.timeType == 0 ? this.form.startTime : ''
                        let endTime = this.form.timeType == 0 ? this.form.endTime : ''
                        let reqData = {
                            ...this.form,
                            startTime: startTime,
                            endTime: endTime,
                            id: this.id,
                            scopeValidList: scopeValidList
                        }

                        this.$Axios._post({
                            url: this.$Config.apiUrl.addUpdateWhite,
                            method: "post",
                            params: {
                                ...reqData
                            }
                        }).then(res=>{
                            if (res.result.code == 0) {
                                this.$message.success('操作成功')
                                this.$router.back()
                                this.$store.dispatch("delView", this.$route);
                            } else {
                                this.$message.error(res.result.message)
                            }
                        })
                    })
                    .catch(err=>{
                        console.log(err);
                    })
                    
                }
            })
        },
        handleChooseScopeType (e) {
            if(e == 4) {
                this.form.scopeType = [4]
            }else {
                if(this.form.scopeType.indexOf(4) != -1){
                    this.form.scopeType.splice(this.form.scopeType.indexOf(4),1)
                }
                if(this.form.scopeType.indexOf(e) != -1) {
                    this.form.scopeType.splice(this.form.scopeType.indexOf(e),1)
                    return
                }
                if(this.form.scopeType.indexOf(e) == -1 && e) {
                    this.form.scopeType.push(e)
                }
            }
        },
        cancelSubmit () {
            this.$confirm('是否确认返回？','温馨提示')
            .then(_=>{
                this.$router.back()
                this.$store.dispatch("delView", this.$route);
            })
            .catch(_=>{})
        },
    },
}
</script>

<style lang="scss" scoped>
.monthCard-edit{
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
    /deep/ .s-search-label {
		width: 145px !important;
		justify-content: flex-start;
	}
    /deep/ .s-nomarl-input .el-input__inner {
		width: 200px;
	}
    /deep/ .date-range{
        margin: 0;
    }
    /deep/ .projectBox{
        &>div{
            margin: 0;
        }
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
}
.inputBox{
    width: 400px;
}
.addBankBtn{
    padding: 0 20px;
    font-size: 14px;
    color: #007AFF;
    line-height: 34px;
    cursor: pointer;
}
.a-mb-10{
    margin-bottom: 10px;
}
/deep/ .coupon-item{
    &>div{
        margin-left: 0 !important;
    }
}
.stationTag{
    padding: 0px 6px;
    background: #f4f4f5;
    color: #909399;
    border: 1px solid #e9e9eb;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    word-break: keep-all;
}
.selectedBtn{
    background: #f2f2f2;
    height: 30px;
    min-width: 70px;
    padding: 0 12px;
    margin-right: 10px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    text-align: center;
    line-height: 30px;
    margin-bottom: 10px;
}
.selectedBtnAc{
    border-color: #007AFF;
    color: #007AFF;
    background: #D8EAFF;
}
</style>